import {useSelector} from 'react-redux';

import {ThemeProvider} from '@mui/material/styles';
import {CssBaseline, IconButton, StyledEngineProvider} from '@mui/material';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import {AuthConsumer, AuthProvider} from "./contexts/authContext";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <AuthProvider>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline/>
           <SnackbarProvider
              dense
              maxSnack={3}
              autoHideDuration={null}
              preventDuplicate
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              action={(snackbarId) => (
                <IconButton sx={{ color: "#FFFFFF" }} onClick={() => closeSnackbar(snackbarId)}>
                  <DeleteIcon />
                </IconButton>
              )}/>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <NavigationScroll>
            <AuthConsumer>
              {
                (auth) => auth.isLoading
                  ? <h1>Loading...</h1>
                  : (<Routes/>)
              }
            </AuthConsumer>
          </NavigationScroll>
          </LocalizationProvider>
        </ThemeProvider>
      </AuthProvider>
    </StyledEngineProvider>
  );
};

export default App;
