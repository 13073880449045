export const permissionsEnum = {
    transactions: "transactions",
    transactionsRead: "transactions:read",

    failedTransactionsRead: "failed_transactions:read",

    positionsRead: "positions:read",
    positionsCancel: "positions:cancel",

    rebuild: "rebuild",
    rebuildPosition: "rebuild:position",
    rebuildTransaction: "rebuild:transaction",
    rebuildRestartFiles: "rebuild:restart_files",
    rebuildResendToCreationQueue: "rebuild:resend_to_creation_queue",
    rebuildAdvanced: "rebuild_advanced",
    rebuildAdvancedResendToPartner: "rebuild_advanced:resend_to_partner",
    rebuildAdvancedResendToLibrary: "rebuild_advanced:resend_to_library",
    rebuildAdvancedResendToAvailability: "rebuild_advanced:resend_to_availability",
    rebuildAdvancedFixExternalSources: "rebuild_advanced:fix_external_sources",

    rebuildMass: "rebuild_mass",
    rebuildMassPositionsRebuild: "rebuild_mass:positions_rebuild",
    rebuildMassPositionsRestartFiles: "rebuild_mass:positions_restart_files",

    filesRead: "files:read",
    filesCopyPath: "files:copy_path",
    filesDownload: "files:download",
    filesRestart: "files:restart",
    filesGenerateMobi: "files:generate_mobi",
    filesGenerateEpub: "files:generate_epub",
    filesGeneratePdf: "files:generate_pdf",
    filesGenerateZip: "files:generate_zip",
    filesGenerateChapter: "files:generate_chapter",
    filesGenerateAudiobook: "files:generate_audiobook",
    filesDownloadExternalSource: "files:download_external_source",
    filesDownloadApplication: "files:download_application",
    filesEuronetResend: "files:euronet_resend",
    filesEuronetResendAdmin: "files:euronet_resend_admin",

    additionalFiles: "additional_files",
    additionalFilesAdd: "additional_files:add",
    additionalFilesDelete: "additional_files:delete",
    additionalFilesDownload: "additional_files:download",
    additionalFilesEdit: "additional_files:edit",
    additionalFilesRead: "additional_files:read",

    client: "client",
    clientDownloadFragment: "client:download_fragment",
    clientDownloadChapter: "client:download_chapter",
    clientDownloadProduct: "client:download_product",
    clientSendTransaction: "client:create_transaction",

    users: "users",
    usersRead: "users:read",
    usersActivate: "users:activate",
    usersSwitch2FA: "users:switch_2fa",
    usersPermissions: "users:permissions",

    logs: "logs"
}
